import {
  brand,
  manufacturer,
  modelName,
  deviceName,
  designName,
  productName,
  deviceYearClass,
  osName,
  osVersion,
} from 'expo-device'
import { Box, Button, Center, Column, HStack, Text, Pressable, useDisclose } from 'native-base'
import React, { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopilot, CopilotStep, walkthroughable } from 'react-native-copilot'
import { useMutation, useQueryClient } from 'react-query'

import { useApi } from 'api'
import Bank from 'components/icons/Bank'
import Congrats from 'components/icons/Congrats'
import InfosIcon from 'components/icons/InfosIcon'
import Pig from 'components/icons/Pig'
import { WonderingPersonSvg } from 'components/icons/WonderingPersonSvg'
import { Loading } from 'components/Loading'
import { SelectDepositAmount, SelectDepositAmountHandle } from 'components/SelectDepositAmount'
import { useHeights } from 'components/TabBar'
import { useGetContractBalance } from 'hooks/useGetContractBalance'
import { useGetEmail } from 'hooks/useGetEmail'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { ConsentsContext } from 'services/consent/provider'
import { IS_WEB } from 'services/platform'
import { QueryKey } from 'services/queryClient'
import { review } from 'services/review'
import { storage } from 'services/storage'
import { useAlertToast } from 'services/toast'
import { TabScreenProps } from 'types/navigation'
import { Banner } from 'ui/Banner'
import { ButtonSolid } from 'ui/Buttons'
import { CustomModal } from 'ui/Modals'
import { useUserContext } from 'user/context'
import { maskIBAN } from 'utils/mask'

type Props = TabScreenProps<'Home'>

type AdvanceModalView = 'request' | 'requestSent'

const ANONYMOUS_USER = 'Anonymous'

export const HomeScreen: React.FC<Props> = ({ navigation }) => {
  const api = useApi()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { showToast } = useAlertToast()
  const { contentHeight } = useHeights()

  const { contract } = useUserContext()
  const { userEmail, setUserEmail } = useGetEmail()
  const { shouldNotTrackPersonalInfo } = useContext(ConsentsContext)
  const [advanceModalView, setAdvanceModalView] = useState<AdvanceModalView>('request')

  const copilot = useCopilot()
  const CopilotBox = walkthroughable(Box)

  const { balance, payPeriodStart, isLoading } = useGetContractBalance(
    contract?.id,
    contract?.organizationId
  )
  const isAdvanceUnavailable = balance && balance.unavailableAdvanceReason

  const startProductTour = useCallback(async () => {
    const hasSeenProductTour = await storage.getObject<boolean | null>('@UserHasSeenProductTour')
    if (IS_WEB || copilot.visible || hasSeenProductTour) return
    // a timeout has been added because product tour may crash if it starts right after rendering
    await new Promise((resolve) => setTimeout(resolve, 1500))
    await copilot.start()
    await storage.save('@UserHasSeenProductTour', true)
  }, [copilot, storage])

  useEffect(() => {
    queryClient.invalidateQueries(QueryKey.Me)
    if (contract) {
      const { user, organization } = contract
      if (!userEmail) setUserEmail(user.email)
      analytics.identify(user.id)
      const properties = {
        $user_id: user.id,
        $name: user.name,
        organization: organization.name,
        brand: brand,
        manufacturer: manufacturer,
        modelName: modelName,
        deviceName: deviceName,
        designName: designName,
        productName: productName,
        deviceYearClass: deviceYearClass,
        osName: osName,
        osVersion: osVersion,
      }
      if (shouldNotTrackPersonalInfo) {
        properties['$name'] = ANONYMOUS_USER
      }
      analytics.setEventPersistedProperties(properties)
      analytics.setProfilePersistedProperties({
        $distinct_id: user.id,
        organizationName: organization.name,
        $name: shouldNotTrackPersonalInfo ? ANONYMOUS_USER : user.name,
        $email: shouldNotTrackPersonalInfo ? ANONYMOUS_USER : user.email,
        $created: user.createdAt,
        netSalary: contract.netSalary,
        hasLoggedIn: contract.usageStatus,
      })
      startProductTour()
    }
  }, [contract, queryClient, userEmail, setUserEmail, shouldNotTrackPersonalInfo, startProductTour])

  const [selectedAmount, setSelectedAmount] = useState<number>(0)
  const [requestedAmount, setRequestedAmount] = useState<number>(0)

  const {
    isOpen: isSendRequestModalOpen,
    onClose: onSendRequestModalClose,
    onOpen: onSendRequestModalOpen,
  } = useDisclose()
  const {
    isOpen: isBalanceCalculationModalOpen,
    onClose: onBalanceCalculationModalClose,
    onOpen: onBalanceCalculationModalOpen,
  } = useDisclose()

  const selectRequestAdvanceRef = useRef<SelectDepositAmountHandle>(null)

  const requestAdvanceMutation = useMutation(
    async () => await api.advancesControllerRequestAdvance({ amount: requestedAmount }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.Transactions)
        await queryClient.invalidateQueries(QueryKey.ContractBalance)
        analytics.log(AnalyticsKey.REQUEST_SENT, {
          amount: selectedAmount,
          balanceBeforeRequest: availableSalary,
        })
        setAdvanceModalView('requestSent')
        setTimeout(() => {
          review.request()
        }, 3000)
      },
      onError: (error) => {
        const isServiceActive =
          !contract?.isManuallyDisabledByAdmin && contract?.organization.canEmployeeRequest
        console.error(error)
        onSendRequestModalClose()
        analytics.log(AnalyticsKey.REQUEST_FAILED, {
          amount: selectedAmount,
          balanceBeforeRequest: availableSalary,
        })
        showToast({
          status: 'warning',
          title: t('error.title'),
          description: isServiceActive ? t('error.generic') : t('error.serviceDeactivated'),
        })
      },
    }
  )

  const openBalanceCalculationModal = () => {
    analytics.log(AnalyticsKey.OPEN_BALANCE_MODAL)
    onBalanceCalculationModalOpen()
  }

  const closeBalanceCalculationModal = () => {
    analytics.log(AnalyticsKey.CLOSE_BALANCE_MODAL)
    onBalanceCalculationModalClose()
  }

  const openSendRequestModalAndSetAmount = () => {
    analytics.log(AnalyticsKey.OPEN_REQUEST_MODAL, { amount: selectedAmount })
    setRequestedAmount(selectedAmount)
    onSendRequestModalOpen()
  }

  const closeSendRequestModalAndResetState = (modalView: AdvanceModalView) => {
    analytics.log(AnalyticsKey.CLOSE_REQUEST_MODAL, {
      amount: selectedAmount,
      origin: modalView + 'Modal',
    })
    onSendRequestModalClose()
    setRequestedAmount(0)
    selectRequestAdvanceRef.current?.reset()
    setAdvanceModalView('request')
  }

  const availableSalary = balance?.availableSalary || 0
  const pendingTransactionsAmount = balance?.pendingTransactionsAmount || 0
  const noTransactionsInProgress = pendingTransactionsAmount == 0
  const helloMessage = contract?.user?.firstName
    ? `${t('home.welcome')} ${contract?.user?.firstName},`
    : `${t('home.welcome')},`

  function renderBalanceCalculationModalContent() {
    if (!contract) return <></>
    const { name: organizationName, payPeriodStart } = contract.organization
    return (
      <Column>
        <Box alignItems="center">
          <Text width="70%" fontSize="lg" fontWeight="bold" color="blueNight" textAlign="center">
            {t('home.modalInfos.title')}
          </Text>
        </Box>
        <Box h={5} />
        <Box marginLeft="3" marginRight="3">
          <Text fontSize="md" fontWeight="semibold" color="blueNight" my="3">
            {t('home.modalInfos.calculations.first.statement')}
          </Text>
          <Text fontSize="sm" color="blueNight" my="2">
            {t('home.modalInfos.calculations.first.content')}
          </Text>
          <Text fontSize="md" fontWeight="semibold" color="blueNight" my="3">
            {t('home.modalInfos.calculations.second.statement')}
          </Text>
          <Text fontSize="sm" color="blueNight" my="2">
            {t('home.modalInfos.calculations.second.content')}
          </Text>
          <Text fontSize="md" fontWeight="semibold" color="blueNight" my="3">
            {t('home.modalInfos.calculations.third.statement')}
          </Text>
          <Text fontSize="sm" color="blueNight" my="2">
            {t('home.modalInfos.calculations.third.content')}
          </Text>
          <Box h={5} />
          <Text fontSize="sm" color="grey" my="3">
            {t('home.modalInfos.helperText', { organizationName, payPeriodStart })}
          </Text>
        </Box>
      </Column>
    )
  }

  function renderSendRequestModalContent() {
    if (advanceModalView === 'request' && noTransactionsInProgress) {
      return (
        <Center h="100%" px={7}>
          <Pig />
          <Text fontSize="lg" fontWeight="bold" color="blueNight" my="3" textAlign="center">
            {t('home.modalSummary.validation', { amount: requestedAmount })}
          </Text>
          <Text fontSize="sm" color="grey" my="3" textAlign="center">
            {t('home.modalSummary.information')}
          </Text>
          <HStack alignItems="center" marginRight="auto" my="3">
            <Bank />
            <Box marginLeft="3">
              <Text fontSize="md" color="blueNight" fontWeight="bold">
                {contract?.user.name}
              </Text>
              <Text fontSize="md" color="grey" mt="2">
                {maskIBAN(contract?.user.iban)}
              </Text>
            </Box>
          </HStack>
        </Center>
      )
    }
    if (advanceModalView === 'request') {
      return (
        <Center h="100%" px={7}>
          <WonderingPersonSvg />
          <Text fontSize="xl" fontWeight="medium" color="blueNight" mt={5} textAlign="center">
            {t('home.modalSummary.transactionsPendingInfo')}
          </Text>
          <Text fontSize="lg" fontWeight="medium" color="blueNight" mt={5} textAlign="center">
            {t('home.modalSummary.transactionsPendingWantToProceed', {
              amount: requestedAmount,
            })}
          </Text>
          <Text fontSize="sm" color="grey" mt={5} textAlign="center">
            {t('home.modalSummary.information')}
          </Text>
        </Center>
      )
    }
    if (advanceModalView === 'requestSent') {
      return (
        <Center h="100%" px={7}>
          <Text color="blueNight" fontSize="2xl" fontWeight="bold" mb={2} textAlign="center">
            {t('home.modalCongrats.title')}
          </Text>
          <Congrats />
          <Text fontSize="lg" fontWeight="bold" color="blueNight" mt={8} textAlign="center">
            {t('home.modalCongrats.validation')} {requestedAmount} €
          </Text>
          <Text fontSize="sm" color="grey" mt={5} textAlign="center">
            {t('home.modalCongrats.information')}
          </Text>
        </Center>
      )
    }
  }

  function renderSendRequestModalFooter() {
    if (advanceModalView === 'request') {
      return (
        <Button.Group space={4} w="100%" justifyContent="space-around">
          <ButtonSolid
            title={t('home.modalSummary.leftButton')}
            colorScheme="secondary"
            isDisabled={requestAdvanceMutation.isLoading}
            onPress={() => closeSendRequestModalAndResetState(advanceModalView)}
            flex={1}
          />
          <ButtonSolid
            title={t('home.modalSummary.rightButton')}
            colorScheme="primary"
            isLoading={requestAdvanceMutation.isLoading}
            onPress={() => requestAdvanceMutation.mutate()}
            flex={1}
          />
        </Button.Group>
      )
    }
    if (advanceModalView === 'requestSent') {
      return (
        <Button.Group space={4} w="100%" justifyContent="space-around">
          <ButtonSolid
            title={t('home.modalCongrats.leftButton')}
            colorScheme="secondary"
            onPress={() => {
              closeSendRequestModalAndResetState(advanceModalView)
              navigation.navigate('Transactions')
            }}
            flex={1}
          />
          <ButtonSolid
            title={t('home.modalCongrats.rightButton')}
            colorScheme="primary"
            onPress={() => closeSendRequestModalAndResetState(advanceModalView)}
            flex={1}
          />
        </Button.Group>
      )
    }
  }

  return (
    <>
      <Column height={contentHeight} width="100%" alignItems="center">
        <Column height="100%" width="100%" maxWidth={520} paddingTop={0}>
          {isAdvanceUnavailable ? (
            <Banner message={t('home.banner', { date: payPeriodStart })} />
          ) : null}

          <Center width="100%" h="25%" paddingTop={isAdvanceUnavailable ? 2 : 0}>
            <CopilotStep
              name="1"
              order={1}
              text={t('productTour.steps.1') + `${contract?.user.firstName} ` + '!'}>
              <CopilotBox copilot={{}} alignItems="center">
                <Text fontSize="3xl" fontWeight="medium" textAlign="center">
                  {helloMessage}
                </Text>
                <Text my={1} fontSize="md">
                  {t('home.information')}
                </Text>
                {isLoading ? (
                  <Loading size={40} />
                ) : (
                  <HStack mx={0} px={0} space={1}>
                    <Text fontSize="3xl" fontWeight="semibold">
                      {`${availableSalary} €`}
                    </Text>
                    <Pressable onPress={openBalanceCalculationModal}>
                      <InfosIcon />
                    </Pressable>
                  </HStack>
                )}
              </CopilotBox>
            </CopilotStep>
          </Center>

          <Center width="100%" h="60%" paddingX={8}>
            <SelectDepositAmount
              userBalance={availableSalary}
              selectedAmount={selectedAmount}
              setSelectedAmount={setSelectedAmount}
              ref={selectRequestAdvanceRef}
            />
          </Center>

          <Center width="100%" h="10%">
            <CopilotStep text={t('productTour.steps.3')} order={3} name="3">
              <CopilotBox copilot={{}}>
                <ButtonSolid
                  title={t('home.actionButton')}
                  colorScheme="primary"
                  w="300px"
                  isDisabled={!selectedAmount}
                  rounded="full"
                  onPress={openSendRequestModalAndSetAmount}
                />
              </CopilotBox>
            </CopilotStep>
          </Center>

          <CustomModal
            modalContentProps={{ height: 580 }}
            isOpen={isSendRequestModalOpen}
            onClose={closeSendRequestModalAndResetState}
            content={renderSendRequestModalContent()}
            footer={renderSendRequestModalFooter()}
          />
          <CustomModal
            modalContentProps={{ height: 580 }}
            isOpen={isBalanceCalculationModalOpen}
            onClose={closeBalanceCalculationModal}
            content={renderBalanceCalculationModalContent()}
          />
        </Column>
      </Column>
    </>
  )
}
