import AsyncStorage from '@react-native-async-storage/async-storage'
import { useState } from 'react'

import { useApi } from 'api'
import { useInterval } from 'hooks/useInterval'
import { useUserContext } from 'user/context'

const TEN_MINUTES = 10 * 60 * 1000

export const useAuth = () => {
  const api = useApi()
  const { logout } = useUserContext()
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined)

  useInterval(
    async () => {
      const currentToken = await AsyncStorage.getItem('@access_token')
      if (!currentToken) return
      api
        .authControllerRefreshToken({
          token: currentToken,
        })
        .then(({ token }) => {
          setAccessToken(token)
        })
        .catch(async () => {
          await logout()
          console.error
        })
    },
    true,
    TEN_MINUTES
  )
  return { accessToken }
}
