import AsyncStorage from '@react-native-async-storage/async-storage'
import { isEmail } from 'class-validator'
import { View, Text, Input, Button, Box, Spinner } from 'native-base'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyleProp,
  ViewStyle,
  TouchableWithoutFeedback,
  Keyboard,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import LogoSvgBig from 'components/NesspaySvgIconBig'
import { OtcInput } from 'components/OtcInput'
import { useGetEmail } from 'hooks/useGetEmail'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { IS_WEB } from 'services/platform'
import { useAlertToast } from 'services/toast'
import { Color } from 'theme'
import { RootScreenProps } from 'types/navigation'

import { useAuthContext } from '../auth/authProvider'

const EMPTY_DESCRIPTION = '******'

type Props = RootScreenProps<'SignIn'>

export const SignInScreen: React.FC<Props> = ({ navigation }) => {
  const api = useApi()
  const { t } = useTranslation()
  const { showToast } = useAlertToast()
  const { setUserEmail: setStoredEmail } = useGetEmail()
  const { setContext, loading: isLoadingAuthContext } = useAuthContext()

  const [code, setCode] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [isEmailValid, setEmailValid] = useState<boolean>(true)
  const [isUserValid, setUserValid] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (IS_WEB) {
      const url = new URL(window.location.href)
      const loginHint = url.searchParams.get('login_hint')
      if (loginHint && isEmail(loginHint)) {
        setEmail(loginHint)
        handleContinue()
      }
    }
    if (email.length === 0) {
      setEmailValid(true)
    }
    if (code.length === 6) {
      validateCodeMutation.mutate()
    }
  }, [code, email])

  const generateCodeMutation = useMutation(
    async () => {
      await api.authControllerGenerateCode({ email })
    },
    {
      onSuccess: () => {
        setUserValid(true)
        setEmailValid(true)
        setStoredEmail(email)
        analytics.log(AnalyticsKey.CODE_SENT)
      },
      onError: () => {
        setUserValid(false)
        setEmailValid(false)
        showToast({
          title: t('signIn.email.emailInputErrorTitle'),
          status: 'error',
          description: t('signIn.email.emailInputNotFound'),
        })
      },
    }
  )

  const validateCodeMutation = useMutation(
    async () => {
      const { token } = await api.authControllerValidateCode({ email, code })
      setContext({ accessToken: token })
      await AsyncStorage.setItem('@access_token', token)
    },
    {
      onSuccess: () => {
        setContext({ isAuthenticated: true })
        analytics.log(AnalyticsKey.LOGIN)
      },
      onError: () => {
        showToast({
          status: 'error',
          title: t('signIn.otc.otcInputHelperText'),
          description: EMPTY_DESCRIPTION,
        })
      },
    }
  )

  async function handleContinue() {
    if (!email) return
    if (!isEmail(email)) {
      showToast({
        title: t('signIn.email.emailInputErrorTitle'),
        status: 'error',
        description: t('signIn.email.emailInputErrorHelper'),
      })
      setEmailValid(false)
    } else {
      if (!isUserValid) {
        await generateCodeMutation.mutateAsync()
      } else {
        if (code.length !== 6) {
          showToast({
            title: t('signIn.otc.otcIsRequired'),
            status: 'error',
            description: EMPTY_DESCRIPTION,
          })
        } else {
          await validateCodeMutation.mutateAsync()
        }
      }
      setEmailValid(true)
    }
  }

  async function resendCode() {
    analytics.log(AnalyticsKey.CLICK_ON_RESEND_CODE)
    await generateCodeMutation.mutateAsync()
    showToast({
      title: t('signIn.otc.otcTitle'),
      status: 'warning',
      description: t('signIn.otc.otcResent'),
    })
  }

  async function handleKeyPress(event: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (event.nativeEvent.key === 'Enter') {
      await handleContinue()
    }
  }

  function goToEmailForgotten() {
    analytics.log(AnalyticsKey.CLICK_ON_MAIL_FORGOTTEN_BUTTON)
    navigation.navigate('EmailForgotten')
  }

  if (isLoadingAuthContext) {
    return <Spinner style={{ height: '100%' }} size="lg" color={Color.blueNight} />
  }

  return (
    <TouchableWithoutFeedback onPress={() => !IS_WEB && Keyboard.dismiss()}>
      <View style={containerStyle}>
        <LogoSvgBig />
        <Text fontSize={'2xl'} fontWeight={500} color={Color.blueNight}>
          {!isUserValid ? t('signIn.title') : t('signIn.otc.otcTitle')}
        </Text>
        <Box h={5} />
        {!isUserValid && (
          <Input
            value={email}
            onChangeText={(email) => setEmail(email)}
            placeholder={t('signIn.email.emailInputLabel')}
            borderColor={Color.grey}
            placeholderTextColor={Color.grey}
            _focus={{
              borderColor: Color.blueNight,
              borderWidth: 2,
            }}
            py={3}
            px={4}
            h={60}
            w={400}
            maxW="80%"
            fontSize="md"
            borderRadius={5}
            returnKeyType="next"
            isInvalid={!isEmailValid}
            onKeyPress={handleKeyPress}
            keyboardType="email-address"
            onSubmitEditing={handleContinue}
          />
        )}
        {isUserValid && (
          <>
            <Text fontSize={'sm'} color={Color.grey}>
              {t('signIn.otc.otcMessage')}
            </Text>
            <Text fontSize={'sm'} color={Color.grey}>
              {email}
            </Text>
            <Box h={5} />
            <OtcInput
              onChange={(code) => setCode(code)}
              width={IS_WEB ? 16 : 12}
              height={IS_WEB ? 16 : 12}
            />
          </>
        )}
        <Button
          mt={4}
          w={400}
          maxW="80%"
          h={10}
          borderRadius={5}
          bg={Color.blueNight}
          isLoading={generateCodeMutation.isLoading || validateCodeMutation.isLoading}
          _pressed={{ bg: Color.grey }}
          _text={{ color: Color.white, fontSize: 'md', fontWeight: 'medium' }}
          _hover={{ bg: Color.grey }}
          onPress={handleContinue}>
          {t('signIn.continue')}
        </Button>
        <Box h={5} />
        {!isUserValid && (
          <Text fontSize={'sm'} onPress={goToEmailForgotten}>
            {t('login.forgotEmailLink')}
          </Text>
        )}
        {isUserValid && (
          <Text fontSize={'sm'} onPress={resendCode}>
            {t('signIn.otc.otcResend')}
          </Text>
        )}
      </View>
    </TouchableWithoutFeedback>
  )
}

const containerStyle: StyleProp<ViewStyle> = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}
