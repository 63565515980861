import React, {
  useMemo,
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react'

import { config } from 'config'

import { useAuth } from './useAuth'

const IOS_REGEX = /iPad|iPhone|iPod/
const ANDROID_REGEX = /android/i

interface AuthContextState {
  organizationId: string | null
  accessToken: string | null
  isAuthenticated: boolean | null
  loading: boolean
}

export interface AuthContextType extends AuthContextState {
  setContext: (value: Partial<AuthContextState>) => void
}

const DEFAULT_AUTH_STATE = {
  organizationId: null,
  accessToken: null,
  isAuthenticated: null,
} as AuthContextType

const DEFAULT_AUTH_CONTEXT = {
  ...DEFAULT_AUTH_STATE,
  setContext: () => {
    // do nothing
  },
} as AuthContextType

export const AuthContext = createContext(DEFAULT_AUTH_CONTEXT)

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { accessToken } = useAuth()
  const [state, setState] = useState<AuthContextState>(DEFAULT_AUTH_STATE)

  const setContext = useCallback(
    (value: Partial<AuthContextState>) => {
      setState((prevValue) => ({ ...prevValue, ...value }))
    },
    [setState]
  )

  const contextValue = useMemo<AuthContextType>(() => {
    const newState = { ...state }
    if (accessToken) {
      newState.isAuthenticated = true
      newState.accessToken = accessToken
      return { ...newState, setContext }
    }
    return { ...state, setContext }
  }, [state, setState, accessToken])

  useEffect(() => {
    if (!window || !window.location) return
    const urlParams = new URLSearchParams(window.location.search)
    const isDeepLink = urlParams.get('source') === 'appLink'
    if (isDeepLink) {
      setTimeout(() => {
        const userAgent = navigator.userAgent || navigator.vendor
        if (IOS_REGEX.test(userAgent)) {
          window.location.href = config.IOS_INTENT_URL
        } else if (ANDROID_REGEX.test(userAgent)) {
          window.location.href = config.ANDROID_INTENT_URL
        }
      }, 1000)
    }
  }, [])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export function useAuthContext() {
  return useContext(AuthContext)
}
